import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetImageLink, GetMoney, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextLine, TextSearch, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheckCircle, faEdit, faFrown, faIdCard, faImage, faInfo, faL, faLayerGroup, faList, faListAlt, faListCheck, faPeace, faPen, faPlus, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { SalonSelect } from './salon_select';
import { UndoRounded } from '@mui/icons-material';


export const ProductsBookingAgent = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [showExport, setShowExport] = useState(false);

    // Vyber Salonu
    const [showSalonSelect, setShowSalonSelect] = useState(false);
    const [salonSelected, setSalonSelected] = useState(false);
    const [showAskCreate, setShowAskCreate] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 80;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_orders(0, year);
        }

    }, []);

    const db_orders = async (typ, year) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_orders_history_agent', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    agent_id: props.admin == true ? 0 : props.user.id,
                    language: props.country.language_id,
                    typ: typ,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_create = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'booking_agent_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_orders(0, actualYear);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'products_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    agent_id: props.admin == true ? 0 : props.user.id,
                    language: props.country.language_id,
                    year: actualYear,
                    email: props.user.email,
                    name: props.user.name + ' ' + props.user.surname,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,
                        status: lang.booking_status_agent,
                        salons: lang.salons,
                        created: lang.created,
                        date: lang.date,
                        bonus_bookings: lang.sc_salon_booking_products,
                        booking_status: lang.booking_status,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.quantity,
                        bonus_export_email: lang.product_export_email,
                        rep: lang.rep,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setSended(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }



    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text, myOrders);
    }

    const Redraw = (items, p, search, mine) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (mine == true) {
            data = data.filter(x => x.agent_id == props.user.id);
        }

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.number, GetDate(x.date), x.salon, x.agent, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, agent, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || agent.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', myOrders);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, myOrders);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ProductCardResult = (value, value2) => {
        setShowOrder(false);
        if (value == true || value2 == true) {
            db_orders(index, actualYear);
        }
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSended(false);

        let year = new Date().getFullYear();
        setActualYear(year);

        if (id == 99) {

        } else {
            setSearch('');
            db_orders(id, year);
        }
    }

    const SelectYearResult = (year, month) => {
        setSended(false);
        setActualYear(year);
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }


    const ChangeMyOrders = (value) => {
        setMyOrders(value);
        Redraw(items, 0, search, value);
    }

    const NewOrder = (typ, item) => {
        setShowSalonSelect(false);

        if (typ == true) {
            setSalonSelected(item);

            setShowAskCreate(true);
        }
    }

    const CreateResult = (value) => {
        setShowAskCreate(false);

        if (value == true) {
            let data = {
                number: '',
                salon_id: salonSelected.id,
                agent_id: props.user.id,
                status: 1,
                note: '',
                language: salonSelected.language,
                creator: true
            }

            db_create(data);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_orders(index, year);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '30%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'flex-end', width: '20%', height: global.dialog_bar_height }}>
                        {props.admin == true && index != 99 ?
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_mine}</p>}
                                control={<Checkbox checked={myOrders} size={'small'} onChange={() => ChangeMyOrders(!myOrders)} />}
                            />
                            : null}
                    </div>
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                        <Button onClick={() => setShowSalonSelect(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                {lang.booking_create_new}
                            </p>
                        </Button>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem value={0}>{lang.booking_filter_active}</MenuItem>
                                    <MenuItem value={1}>{lang.booking_filter_sended}</MenuItem>
                                    <MenuItem value={2}>{lang.booking_filter_finished}</MenuItem>
                                    <MenuItem value={99}>{lang.booking_export}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.booking_filter_active}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.booking_filter_sended}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.booking_filter_finished}</p>
                                        </div>
                                    </Button>

                                    <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.booking_export}</p>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            {index == 99 ?
                                <div style={{ ...styles.Block }}>
                                    {/* *********************************************************************************************************  
                                            EXPORT OBJEDNAVOK
                                    ********************************************************************************************************* */}
                                    <div style={{ ...styles.Block, height: 70 }}>
                                        <p style={{ ...styles.TextLarge }}>{lang.booking_export_label}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.booking_export_text}</p>
                                    </div>

                                    <div style={{ ...styles.Block, height: 70 }}>
                                        <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.year}</p>
                                        <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 40 }}>
                                        {sended == true ?
                                            <div style={{ ...styles.Block, width: 400 }}>
                                                <FormOK text={lang.email_sended} small />
                                            </div>
                                            : <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDark, width: 220 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                                {lang.export}
                                            </Button>

                                        }
                                    </div>
                                </div>
                                :
                                <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>
                                    {index == 1 || index == 2 ?
                                        <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, height: 60 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.orders_year_list}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {/* *********************************************************************************************************  
                                            ZOZNAM OBJEDNAVOK
                                    ********************************************************************************************************* */}
                                    {props.isSmall ?
                                        <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10 }}>
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                {props.admin == true && index != 99 ?
                                                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_mine}</p>}
                                                        control={<Checkbox checked={myOrders} size={'small'} onChange={() => ChangeMyOrders(!myOrders)} />}
                                                    />
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <Button onClick={() => setShowSalonSelect(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                        {lang.booking_create_new}
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        : null}

                                    {itemsFiltered != false ?
                                        itemsFiltered.map((item) => (
                                            <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: props.isSmall ? 50 : rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.Block, maxHeight: rowWidth }}>
                                                            <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.booking_color_agent[item.status], borderRadius: '50%' }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={global.booking_icon_agent[item.status]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.isSmall ?
                                                        <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left', marginBottom: 10 }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                            {item.salon != false ?
                                                                <p style={{ ...styles.TextTinyMobile, fontWeight: '400', textAlign: 'left' }}>{item.salon}</p>
                                                                : null}
                                                            <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                            {props.admin == true && myOrders == false ?
                                                                <p style={{ ...styles.TextTiny }}>{lang.rep}: {item.agent}</p>
                                                                : null}
                                                            {item.reklamacia == true ?
                                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600', marginTop: 10 }}>{lang.booking_reklamacia_true}</p>
                                                                :
                                                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.booking_status_agent[item.status]}</p>
                                                            }
                                                        </div>
                                                        :
                                                        <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                                    {item.salon != false ?
                                                                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.salon}</p>
                                                                        : null}
                                                                </div>

                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                        <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                        {props.admin == true && myOrders == false ?
                                                                            <p style={{ ...styles.TextTiny }}>{item.agent}</p>
                                                                            : null}
                                                                    </div>
                                                                    <div style={{ ...styles.Block, width: '30%' }}>
                                                                        {index == 0 ?
                                                                            <p style={{ ...styles.TextTiny }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                                            : null}
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                        {item.reklamacia == true ?
                                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                            :
                                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.booking_status_agent[item.status]}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Paper>
                                        )) :
                                        // ZIADNE OBJEDNAVKY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }

                                    {itemsFiltered != false ?
                                        pageMax > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                            </div>
                                            : null
                                        : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {showSalonSelect == true ?
                <SalonSelect isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} order_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} title={lang.salon_choose} sub_title={lang.booking_create_new} func={NewOrder.bind(this)} />
                : null}

            {showOrder == true ?
                <ProductBookingDetailsAgent isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} order_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ProductCardResult.bind(this)} />
                : null}

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_export} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showAskCreate == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_create_title} sub_title={''} text={lang.booking_create_text} sub_text={salonSelected.name + ' ' + salonSelected.surname} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CreateResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const ProductBookingDetailsAgent = (props) => {

    const image = require('./react/app/vzorka.jpg');

    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [order, setOrder] = useState(false);
    const [orderStatus, setOrderStatus] = useState(0);
    const [items, setItems] = useState(false);
    const [changes, setChanges] = useState(false);
    const [money, setMoney] = useState('');
    const [dph, setDph] = useState(1);

    // UDAJE O ZAKAZNIKOVI
    const [salon, setSalon] = useState(false);

    // poznámka k objednávke
    const [note, setNote] = useState('');

    const [showOK, setShowOK] = useState(false);

    // Reklamacia
    const [showReklamacia, setShowReklamacia] = useState(false);
    const [reklamacia, setReklamacia] = useState(false);
    const [reklamaciaCount, setReklamaciaCount] = useState(0);

    // Suma
    const [sum, setSum] = useState(0);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // pridať produkt
    const [showProducts, setShowProducts] = useState('');

    // zobraziť kartu salóna
    const [showSalonCard, setShowSalonCard] = useState(false);

    // zmena stavu
    const [showStatusChange, setShowStatusChange] = useState(false);
    const [statusTMP, setStatusTMP] = useState(0);

    // Export objednávky
    const [showExport, setShowExport] = useState(false);

    // AKCIOVA PONUKA
    const [actionProducts, setActionProducts] = useState(false);
    const [action, setAction] = useState(true);
    const [actionCount, setActionCount] = useState(0);
    const [tags, setTags] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const [showCrmMissing, setShowCrmMissing] = useState(false);
    const [changed, setChanged] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const infoHeight = 34;
    const buttonHeight = 100;
    const rowHeight = 100;
    const labelHeight = 160;
    const actionSize = 120;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_booking_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    booking_id: props.order_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setMoney(GetMoney(json.order.language));
                setItems(json.items);
                setSum(json.sum);
                setNumber(json.order.number);
                setOrder(json.order);
                setNote(json.order.note);
                setChanges(json.order.changes == false ? false : true);
                setOrderStatus(json.order.status);
                setSalon(json.salon);
                setReklamaciaCount(json.reklamacia_count);
                setActionProducts(json.action_items);
                setDph(json.dph);
                setActionCount(GetAction(json.items));
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update_order = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_booking_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                order.status = data.status;
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_buy_agent', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.product_id == data.product_id);
                if (tmp != undefined) {
                    tmp.quantity = data.quantity
                    setSum(json.sums);
                    setChanges(json.changes == false ? false : true);
                }

                setActionCount(GetAction(items));

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_add = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_add_agent', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setChanged(true);
                db_get();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'booking_order_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_id: props.order_id,
                    user_id: props.user.id,
                    language: props.country.language_id,
                    email: props.user.email,
                    sum: sum,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,

                        status: lang.booking_status_agent,
                        created: lang.created,
                        date: lang.date,
                        label: lang.desktop_booking_products,
                        sub_label: number,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.quantity,
                        rep: lang.rep,
                        settings_company: lang.settings_company,
                        note: lang.note,
                        money: money,
                        cost: lang.cost,
                        pieces: lang.pieces,
                        sum_to_pay: lang.sum_to_pay,
                        booking_date: lang.booking_date,
                        booking_status: lang.booking_status,

                        email_subject: lang.export_data_,
                        email_label: lang.export_data_ + ' - ' + lang.desktop_booking_products,
                        email_text: lang.booking_export_,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        var redraw = false;
        if (orderStatus != order.status) {
            redraw = true;
        }
        if (reklamacia == true) {
            redraw = true;
        }
        if (changed == true) {
            redraw = true;
        }
        func(redraw);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const PlusMinusResult = (value, item) => {

        let data = {
            id: item.id,
            salon_id: item.salon_id,
            product_id: item.product_id,
            quantity: value,
            booking_id: item.booking_id,
        }

        setChanged(true);
        db_update(data);
    }


    const AddToCard = (item, quantity) => {

        let data = {
            id: 0,
            salon_id: salon.id,
            product_id: item.id,
            quantity: quantity,
            sended: true,
            booking_id: order.id,
            quantity_original: 0,
            cost: item.cost_price
        }
        Debug(data);
        db_product_add(data);
    }

    const ChangeStatus = (status) => {
        let data = {
            id: order.id,
            status: status,
        }

        db_update_order(data);
    }

    const ChangeStatusAsk = (status) => {
        if (salon.crm_id != '') {
            setStatusTMP(status);
            setShowStatusChange(true);
        } else {
            setShowCrmMissing(true);
        }
    }

    const ChangeStatusResult = (value) => {
        setShowStatusChange(false);

        if (value == true) {
            ChangeStatus(statusTMP);
        }
    }

    const ExportResult = (value) => {
        setShowExport(false);
        if (value == true) {
            db_export();
        }
    }

    const ProductSelectResult = (typ, item) => {
        setShowProducts(false);
        if (typ == true) {
            let data = {
                id: 0,
                salon_id: salon.id,
                product_id: item.id,
                quantity: 1,
                sended: true,
                booking_id: order.id,
                quantity_original: 0,
                cost: item.cost_price
            }

            db_product_add(data);
        }
    }

    const GetAction = (items_) => {
        var count = 0
        var tags_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (items_ != false) {
            items_.forEach(element => {
                if (element.category_id == 4 || element.category_id == 10) {
                    count = count + parseInt(element.quantity);
                }
                if (element.tags != false) {
                    element.tags.forEach(tag => {
                        tags_[tag] += parseInt(element.quantity);
                    });
                }
            });
        }
        setTags(tags_);
        return count;
    }

    const ActionRow = (props) => {
        // excluded -> len ak položky excluded nie sú v košíku
        var inCardExcluded = false;

        var item = actionProducts != false ? actionProducts.find(x => x.code == props.code && parseInt(x.action) == 1 && parseInt(x.booking) == 1) : undefined;
        var inCardItem = items != false ? items.find(x => x.code == props.code && parseInt(x.quantity) > 0) : false;

        // Nachádzajú sa v košíku vylúčené produkty
        if (props.excluded != undefined) {
            inCardExcluded = items != false ? items.some(x => props.excluded.includes(x.code) && parseInt(x.quantity) > 0) : false;
        }

        var inCard = inCardItem == false ? false : inCardItem == undefined ? false : true;
        if (inCardItem != false && inCardItem != undefined) {
            //inCardItem.quantity_max = props.count;
        }

        return (
            item != undefined ?
                <Paper elevation={3} style={{ ...styles.Block, width: actionSize, minHeight: actionSize, margin: 5, borderRadius: 5 }}>
                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: actionSize, height: actionSize, objectFit: 'contain', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}></img>
                    <div style={{ ...styles.Block, width: '98%', height: 30, overflow: 'hidden', }}>
                        <TextLine text={item.label} fontSize={global.font_tiny} fontWeight={'600'} color={global.theme_black} />
                    </div>
                    <div style={{ ...styles.Block, width: '98%', borderTop: '1px solid #d0d0d0', height: 30, overflow: 'hidden' }}>
                        <p style={{ ...styles.TextXSmall, color: global.theme_black }}>{props.count} {lang.pieces}</p>
                    </div>
                    <div style={{ ...styles.Block, width: '98%', height: 36 }}>
                        {props.count > 0 && inCardExcluded == false ?
                            inCard == true ?
                                <p style={{ ...styles.TextXSmall, color: global.theme_black }}>{lang.product_in_basket}</p>
                                :
                                <Button onClick={() => AddToCard(item, props.count)} style={{ ...styles.ButtonDark, height: 36, width: actionSize, borderRadius: 0 }}>{lang.product_buy}</Button>
                            :
                            inCard == true || inCardExcluded == true ?
                                <Chip size={'small'} label={lang.product_no_basket} style={{ ...styles.TextXSmall, color: global.theme_white, backgroundColor: global.theme_dark_red }} />
                                : null
                        }
                    </div>
                </Paper>
                : null
        );
    }

    function getProductQuantity(code) {
        var quantity = 0;
        if (items != false) {
            var tmp = items.find(x => x.code == code);
            if (tmp != undefined) {
                quantity = tmp.quantity;
            }
        }

        return quantity;
    }

    const ActionRow1 = (props) => {

        var item = actionProducts != false ? actionProducts.find(x => x.code == props.code && parseInt(x.action) == 1 && parseInt(x.booking) == 1) : undefined;
        var inCardItem = items != false ? items.find(x => x.code == props.code && parseInt(x.quantity) > 0) : false;
        var inCard = inCardItem == false ? false : inCardItem == undefined ? false : true;
        if (inCardItem != false && inCardItem != undefined) {
            //inCardItem.quantity_max = props.count;
        }

        return (
            item != undefined ?
                <Paper elevation={3} style={{ ...styles.Block, width: actionSize, minHeight: actionSize, margin: 5, borderRadius: 5 }}>
                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: actionSize, height: actionSize, objectFit: 'contain', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}></img>
                    <div style={{ ...styles.Block, width: '98%', height: 30, overflow: 'hidden', }}>
                        <TextLine text={item.label} fontSize={global.font_tiny} fontWeight={'600'} color={global.theme_black} />
                    </div>
                    <div style={{ ...styles.Block, width: '98%', borderTop: '1px solid #d0d0d0', height: 30, overflow: 'hidden' }}>
                        <p style={{ ...styles.TextXSmall, color: global.theme_black }}>{props.count} {lang.pieces}</p>
                    </div>
                    <div style={{ ...styles.Block, width: '98%', height: 36 }}>
                        {props.count > 0 ?
                            inCard == true ?
                                <p style={{ ...styles.TextXSmall, color: global.theme_black }}>{lang.product_in_basket}</p>
                                :
                                <Button onClick={() => AddToCard(item, props.count)} style={{ ...styles.ButtonDark, height: 36, width: actionSize, borderRadius: 0 }}>{lang.product_buy}</Button>
                            :
                            inCard == true ?
                                <Chip size={'small'} label={lang.product_no_basket} style={{ ...styles.TextXSmall, color: global.theme_white, backgroundColor: global.theme_dark_red }} />
                                : null
                        }
                    </div>
                </Paper>
                : null
        );
    }


    const ActionRowInfo = (sett) => {
        // Zobrazenie len informacie


        return (
            <Paper elevation={3} style={{ ...styles.Block, width: actionSize + 20, minHeight: actionSize + 30 + 30 + 36, margin: 5, borderRadius: 5 }}>
                <div style={{ ...styles.Block, width: '98%' }}>
                    <img src={image} style={{ width: '100%', height: '100%', width: actionSize, height: actionSize, objectFit: 'contain', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}></img>
                    <div style={{ ...styles.Block, width: '98%', height: 30, overflow: 'hidden', }}>
                        <TextLine text={lang.action_info_1} fontSize={global.font_tiny} fontWeight={'600'} color={global.theme_black} />
                    </div>
                    <div style={{ ...styles.Block, width: '98%', borderTop: '1px solid #d0d0d0', height: 30, overflow: 'hidden' }}>
                        <p style={{ ...styles.TextXSmall, color: global.theme_black }}>10 ks</p>
                    </div>
                    <div style={{ ...styles.Block, width: '98%', height: 36 }}>
                        {sett.show == true &&
                            <Chip size={'small'} label={lang.action_info_2} style={{ ...styles.TextTiny, color: global.theme_white, backgroundColor: global.theme_darker_green }} />}
                    </div>
                </div>
            </Paper>
        );
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: labelHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ?
                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.BlockLeft, width: '90%', height: labelHeight, marginLeft: 10, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTinyMobile, fontWeight: '600' }}>{props.lang.desktop_booking_products}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 2, textAlign: 'left' }}>{lang.booking_number}: <b>{number}</b></p>

                            <p style={{ ...styles.TextTinyMobile, marginTop: 10 }}>{salon.name} {salon.surname}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{salon.street} {salon.street_number}, {salon.psc} {salon.town}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 4 }}>{lang.email_}: {salon.email}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{lang.mobil}: {salon.mobil}</p>
                        </div>
                        : null}
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockCenter, width: 100, height: labelHeight }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10, marginTop: 20 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingCart} />
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.Block, width: '40%', height: labelHeight }}>
                            <div style={{ ...styles.BlockLeft, height: labelHeight - 20, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{props.lang.desktop_booking_products}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.booking_number}: {order.order_number} / <b>{number}</b></p>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.Block, width: 50, height: 70, marginTop: 14, backgroundColor: global.themes[props.theme].theme_medium, borderRadius: 5 }}>
                                            <FontAwesomeIcon style={{ height: 28, color: global.theme_white }} icon={faIdCard} />
                                            <p style={{ ...styles.TextXXTiny, color: global.theme_white, marginTop: 5 }}>{lang.card}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                        <p style={{ ...styles.TextTiny, marginTop: 14 }}>{salon.name} {salon.surname}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.street} {salon.street_number}, {salon.psc} {salon.town}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 4 }}>{lang.email_}: {salon.email}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.mobil}: {salon.mobil}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: '30%', height: labelHeight }}>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left' }}>{lang.settings_company}:</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{salon.company}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.street} {salon.street_number}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.psc} {salon.town}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.ico}: {salon.ico}</p>
                                {salon.language == 0 ?
                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.dic}: {salon.dic}</p>
                                    : null}
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.ic_dph}: {salon.ic_dph}</p>
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 240, height: labelHeight }}>
                            <div style={{ ...styles.Block, height: labelHeight - 30, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.sum_to_pay}</p>
                                <p style={{ ...styles.TextLarge, fontWeight: '600', marginTop: 5 }}>{GetPrice(ConvertToPrice(sum))} {money}</p>
                                <p style={{ ...styles.TextXTiny, fontWeight: '400' }}>{lang.with_dph}</p>

                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{GetPrice(ConvertToPrice(sum / dph))} {props.lang.money} {lang.without_dph}</p>
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? 150 : 200, height: labelHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <IconButton onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white, marginTop: 15, marginRight: props.isSmall ? 20 : 36 }}>
                                <FontAwesomeIcon style={{ width: 16, color: global.themes[props.theme].theme_darker }} icon={faShare} />
                            </IconButton>

                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 15 }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - labelHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight }}>
                        {/* BODY */}
                        {order.reklamacia == true ?
                            <div style={{ ...styles.Block, height: infoHeight, backgroundColor: global.theme_dark_red }}>
                                {reklamaciaCount > 1 ?
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.booking_reklamacia_count}: {reklamaciaCount}</p>
                                    :
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.booking_reklamacia_status}: {lang.reklamacia_status[order.reklamacia_status]}</p>
                                }
                            </div>
                            :
                            changes == true && order.creator == false ?
                                <div style={{ ...styles.Block, height: infoHeight, backgroundColor: global.theme_dark_red }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.booking_changes}</p>
                                </div>
                                :
                                <div style={{ ...styles.Block, height: infoHeight }}>
                                    <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_dark }}>{lang.products_list}</p>
                                </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - infoHeight, overflowY: 'scroll', paddingBottom: 10 }}>

                            {action == true && (order.status == 0 || order.status == 1) ?
                                //
                                // ************************************************************************************
                                // AKCIOVA PONUKA
                                // ************************************************************************************
                                //
                                <Paper elevation={0} style={{ ...styles.Block, width: '96%', backgroundColor: global.theme_back, paddingTop: 10, paddingBottom: 10, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.Block, width: undefined }}>
                                        <p style={{ ...styles.TextXSmall, color: global.theme_black }}><b>{lang.action_offer} -</b> {lang.action_text_}:</p>
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 5 }}>
                                        {actionProducts != false ?
                                            <div style={{ ...styles.BlockRow, flexWrap: 'wrap', justifyContent: 'center' }}>
                                                <ActionRow code={'V470202'} count={parseInt(actionCount / 2)} />
                                                <ActionRow code={'P910156'} count={parseInt(actionCount / 3)} />
                                                <ActionRow code={'EXXR007'} count={Math.min(tags[1], tags[2])} />
                                                <ActionRow code={'V680601'} count={parseInt(tags[3] / 2) + parseInt(tags[4])} />
                                                <ActionRow code={'V680702'} count={parseInt(tags[5]) >= 8 ? parseInt(tags[5] / 2) : 0} />
                                                <ActionRow code={'EXXR008'} excluded={['V243601', 'P910126']} count={Math.min(parseInt(tags[6] / 2) + tags[1], tags[2])} />
                                                <ActionRow code={'V681301'} count={parseInt(tags[8] / 4)} />
                                                <ActionRow code={'V243601'} excluded={['EXXR008']} count={parseInt(tags[9] / 2)} />
                                                <ActionRow code={'P910126'} excluded={['EXXR008']} count={parseInt(tags[9] / 2)} />
                                                <ActionRow code={'V243701'} count={getProductQuantity('V24370') >= 4 ? 1 : 0} />

                                                <ActionRowInfo show={getProductQuantity('V24370') == 2 || getProductQuantity('V24370') == 3 ? true : false} />

                                            </div>
                                            : null}
                                    </div>
                                    <p style={{ ...styles.TextXSmall, color: global.theme_dark, marginTop: 10 }}>{lang.action_product_add}</p>
                                </Paper>
                                : null}

                            {note != '' ?
                                //
                                // ************************************************************************************
                                // POZNAMKA K OBJEDNAVKE
                                // ************************************************************************************
                                //
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.Block, width: '98%' }}>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.note}:</p>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{note}</p>
                                    </div>
                                </Paper>
                                : null}

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: parseInt(item.quantity) != parseInt(item.quantity_original) ? global.theme_highlight_red : global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: props.isSmall ? 20 : rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '45%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left' }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.cost_}: {GetPrice(item.cost_price)} {money}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.cost_}: {GetPrice(item.cost_price)} {money}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                {order.status == 2 ?
                                                                    item.crm_sended == 1 ?
                                                                        <p style={{ ...styles.TextTiny }}>{lang.booking_crm_sended}</p>
                                                                        :
                                                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{lang.booking_manual_sended}</p>
                                                                    : null}
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                {parseInt(item.quantity) != parseInt(item.quantity_original) ?
                                                                    <p style={{ ...styles.TextTiny, marginRight: 25 }}>{lang.booking_product_ordered}: {item.quantity_original} {item.unit == 1 ? lang.pieces : lang.pack}</p>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {props.preview == true ? parseInt(item.quantity) != parseInt(item.quantity_original) ?
                                                <div style={{ ...styles.Block, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{lang.booking_product_ordered}: {item.quantity_original} {item.unit == 1 ? lang.pieces : lang.pack}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.booking_product_received}: {item.quantity} {item.unit == 1 ? lang.pieces : lang.pack}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.Block, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextSmall }}>{item.quantity} {item.unit == 1 ? lang.pieces : lang.pack}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.Block, height: rowHeight, width: props.isSmall ? 150 : 180, justifyContent: 'center' }}>
                                                    {order.status == 1 ?
                                                        <div style={{ ...styles.Block }}>
                                                            <p style={{ ...styles.TextTiny, marginBottom: 2 }}>{item.unit == 1 ? lang.quantity : lang.quantity_bals}</p>
                                                            <PlusMinus data={item} value={item.quantity} func={PlusMinusResult.bind(this)} />
                                                        </div>
                                                        :
                                                        <div style={{ ...styles.Block }}>
                                                            <p style={{ ...styles.TextSmall }}>{item.quantity} {item.unit == 1 ? lang.pieces : lang.pack}</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighteen, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {props.preview != true ?
                            props.isSmall ?
                                /*
                                *
                                * =================================================================================================================
                                * SMALL SCREEN
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: 15 }}>{lang.booking_status}: <b>{order != false ? lang.booking_status_agent[order.status] : null}</b></p>
                                    {order.status == 0 ?
                                        <Button onClick={() => ChangeStatus(1)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>{lang.booking_status_agent[1]}</Button>
                                        : null}

                                    {order.status == 1 ?
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <IconButton onClick={() => setShowProducts(true)} style={{ width: 36, height: 36, backgroundColor: global.themes[props.theme].button_dark }}>
                                                <FontAwesomeIcon style={{ width: 20, color: global.theme_white }} icon={faPlus} />
                                            </IconButton>
                                            <Button onClick={() => ChangeStatusAsk(2)} style={{ ...styles.ButtonGreen, width: 150, marginLeft: 15 }}>
                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.booking_button_send_manual}</p>
                                            </Button>
                                            <Button onClick={() => ChangeStatusAsk(7)} style={{ ...styles.ButtonRed, backgroundColor: global.theme_dark_blue, width: 150, marginLeft: 15 }}>
                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.booking_button_send_auto}</p>
                                            </Button>
                                        </div>
                                        : null}
                                    {order.status == 3 ?
                                        <Button onClick={() => ChangeStatusAsk(6)} style={{ ...styles.ButtonRed, width: 180 }}>{lang.booking_cancel}</Button>
                                        : null}
                                </div>
                                :
                                /*
                                *
                                * =================================================================================================================
                                * LARGE SCREEN
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.Block, width: 220, height: buttonHeight }}>
                                        {order.status == 1 ?
                                            <Button onClick={() => setShowProducts(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180, marginTop: 22 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                    {lang.eshop_item_add}
                                                </p>
                                            </Button>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: '68%', height: buttonHeight }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.booking_status}: <b>{order != false ? lang.booking_status_agent[order.status] : null}</b></p>
                                        {items != false ?
                                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    {order.status == 0 ?
                                                        <Button onClick={() => ChangeStatus(1)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>{lang.booking_status_agent[1]}</Button>
                                                        : null}
                                                    {order.status == 1 ?
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <Button onClick={() => ChangeStatusAsk(2)} style={{ ...styles.ButtonGreen, width: 180, marginLeft: 10, marginRight: 10 }}>{lang.booking_button_send_manual}</Button>
                                                            <Button onClick={() => ChangeStatusAsk(7)} style={{ ...styles.ButtonRed, backgroundColor: global.theme_dark_blue, width: 180, marginLeft: 10 }}>{lang.booking_button_send_auto}</Button>
                                                        </div>
                                                        : null}
                                                    {order.status == 3 ?
                                                        <Button onClick={() => ChangeStatusAsk(6)} style={{ ...styles.ButtonRed, width: 180 }}>{lang.booking_cancel}</Button>
                                                        : null}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 220, height: buttonHeight }}>
                                        <div style={{ ...styles.Block, marginTop: 22 }}>
                                            {(order.status == 0 || order.status == 1) && isBusy == false ?
                                                <Button onClick={() => ChangeStatusAsk(6)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 180 }}>{lang.booking_cancel}</Button>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            :
                            <div style={{ ...styles.Block, width: '50%', height: buttonHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny }}>{lang.booking_status}: <b>{order != false ? lang.booking_status_agent[order.status] : null}</b></p>
                            </div>
                        }
                    </div>
                </div>
            </Paper>

            {showCrmMissing == true ?
                <DialogInfo radius={props.radius} title={lang.booking_missing_id} sub_title={''} text={lang.booking_missing_id_text} sub_text={lang.booking_missing_id_sub_text} icon={faInfo} button={props.lang.close} icon_show={faFrown} icon_color={global.theme_dark_red} theme={props.theme} func={() => setShowCrmMissing(false)} />
                : null}

            {showProducts == true ?
                <ProductSelect cabine={true} isSmall={props.isSmall} order_id={order.id} employee_id={props.employee_id} employees={props.employees} user={salon} lang={lang} language={props.country.language_id} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                : null}

            {showSalonCard == true ?
                <SalonCard salon_id={salon.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={lang.salons_list} sub_title={lang.salons} func={() => setShowSalonCard(false)} />
                : null}

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_export_} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showStatusChange == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.eshop_ask_email} sub_title={''} text={lang.ask_order_change_status} sub_text={lang.booking_status_agent_text[statusTMP]} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ChangeStatusResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}